import React from 'react';

function Contact() {
  return (
    <div className="contact">
      <div className="contact-container">
        <h1>Contact Us</h1>
        <div>
          For promotion, booking, merch questions, or just anything really email
          us: stablemateband@gmail.com
        </div>
      </div>
    </div>
  );
}

export default Contact;
