import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import './Stablemain.css';
import '../App.css';

function Stablemain() {
  const [mobileBack, setMobileBack] = useState(false);

  const showMobile = () => {
    console.log(window.innerWidth);
    if (window.innerWidth <= 989) {
      setMobileBack(false);
    } else {
      setMobileBack(true);
    }
  };

  useEffect(() => {
    showMobile();
  }, []);

  window.addEventListener('resize', showMobile);

  return (
    <div className="stablemain-container">
      {mobileBack ? (
        <video src="/videos/palm_clip.mp4" autoPlay loop muted />
      ) : null}
      <div className="stablemain-btns">
        {mobileBack ? (
          <>
            <a
              href="https://www.youtube.com/channel/UC0K3MYUQiq5-K4nu-7-PSww"
              className="btn-mobile"
              target="_blank"
            >
              <Button
                className="btns"
                buttonStyle="btn--outline"
                buttonSize="btn--large"
              >
                Watch <i className="fa fa-play-circle" />
              </Button>
            </a>
            <a
              href="https://open.spotify.com/artist/6DmA8P2WbA9VkRhu28sOFh"
              className="btn-mobile"
              target="_blank"
            >
              <Button
                className="btns"
                buttonStyle="btn--outline"
                buttonSize="btn--large"
              >
                Listen <i className="fa fa-music" />
              </Button>
            </a>
            <a
              href="https://www.instagram.com/_stablemate/"
              className="btn-mobile"
              target="_blank"
            >
              <Button
                className="btns"
                buttonStyle="btn--outline"
                buttonSize="btn--large"
              >
                Follow <i className="fab fa-instagram" />
              </Button>
            </a>
          </>
        ) : (
          <>
            <a
              href="https://open.spotify.com/album/1i3duYAotDoqNrBkHvZVah"
              className="btn-mobile"
              target="_blank"
            >
              <Button
                className="btns"
                buttonStyle="btn--outline"
                buttonSize="btn--x-large"
              >
                <img className="spot-image" src="images/spotify.png" />
              </Button>
            </a>
          </>
        )}
      </div>
    </div>
  );
}

export default Stablemain;
