import React from 'react';
import './Cards.css';
import CardItems from './CardItems';

function Cards() {
  return (
    <div className="cards">
      <div className="title-container">
        <h1>Pokédex</h1>
      </div>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItems
              src="images/conor.jpg"
              text="vocals, guitar, cello bow, fortnite"
              label="Conor"
            />
            <CardItems
              src="images/mitch.jpg"
              text="vocals, guitar, best tennis player in the band"
              label="Mitch"
            />
            <CardItems
              src="images/daniel.jpg"
              text="drums, coffee expert, racial ambiguity"
              label="Daniel"
            />
            <CardItems
              src="images/anna.jpg"
              text="vocals, better than Mitch at all videogames"
              label="Anna"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
